import NextHead from "next/head";
import { useRouter } from "next/router";

const REFERRAL_IMAGE = "https://bunches-discover-assets.s3.amazonaws.com/Referral+Invite+Image.png";

export default function FrameMeta({ frame }) {
  const router = useRouter();
  if (!frame) return;

  return (
    <NextHead>
      {/* Versioning */}
      <meta property="of:version" content={frame.frameVersion ?? "vNext"} />
      <meta property="of:accepts:farcaster" content={frame.frameVersion ?? "vNext"} />
      <meta property="fc:frame" content={frame.frameVersion ?? "vNext"} />

      {/* Title */}
      <meta property="og:title" content={frame.title ?? "On Bunches"} />
      <meta property="twitter:title" content={frame.title ?? "On Bunches"} />

      {/* Frame Image */}
      <meta property="of:image" content={frame.imageUrl ?? REFERRAL_IMAGE} />
      <meta property="og:image" content={frame.imageUrl ?? REFERRAL_IMAGE} />
      <meta property="fc:frame:image" content={frame.imageUrl ?? REFERRAL_IMAGE} />
      <meta name="twitter:image" content={frame.imageUrl ?? REFERRAL_IMAGE} />

      {/* Post URL */}
      <meta property="of:post_url" content={frame.postUrl ?? `https://onbunches.com/message/${router.query.id}`} />
      <meta
        property="fc:frame:post_url"
        content={frame.postUrl ?? `https://onbunches.com/message/${router.query.id}`}
      />

      {/* Button */}
      <meta property="of:button:1" content={frame.frameButton.title ?? "On Bunches"} />
      <meta property="of:button:1:action" content={frame.frameButton.action ?? "link"} />
      <meta
        name="twitter:url"
        content={frame.frameButton.target ?? `https://onbunches.com/message/${router.query.id}`}
      />
      <meta
        property="of:button:1:target"
        content={frame.frameButton.target ?? `https://onbunches.com/message/${router.query.id}`}
      />
      <meta property="fc:frame:button:1" content={frame.frameButton.title ?? "On Bunches"} />
      <meta property="fc:frame:button:1:action" content={frame.frameButton.action ?? "link"} />
      <meta
        property="fc:frame:button:1:target"
        content={frame.frameButton.target ?? "https://onbunches.com/message/${router.query.id}"}
      />
    </NextHead>
  );
}
